<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b" v-if="isReady">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          {{ $t('Project_description.list') }}
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ $t('GENERAL.TOTAL_OF_LIST', [total_post]) }}
        </span>
      </h3>

      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li>
            <router-link :to="{name: 'project_description.add'}" class="btn btn-sm btn-info">
              {{ $t('Project_description.add') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="width: 50px">#</th>
              <th class="p-0" style="min-width: 450px">
                {{ $t('Project_description.title') }}
              </th>
              <th class="p-0" style="min-width: 150px">
                {{ $t('Project_description.status') }}
              </th>
              <th class="p-0" style="min-width: 150px">
                {{ $t('Project_description.last_update') }}
              </th>
              <th class="p-0 text-right" style="min-width: 150px">
                {{ $t('GENERAL.option') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in dataToShow">
              <tr v-bind:key="i">
                <td class="pl-0 py-4">
                  <div class="symbol symbol-50 symbol-light mr-1">
                    {{ item.id }}
                  </div>
                </td>
                <td class="pl-0">
                  <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                    {{ item.title | str_limit(128) }}
                  </a>
                  <div class="text-muted font-weight-bold">
                    {{ item.body | str_limit(128) | stripHTML }}
                  </div>
                </td>
                <td class="pl-0">
                  <div>
                    <b-badge variant="success" v-if="item.status == 1">Published</b-badge>
                    <b-badge variant="light" v-if="item.status == 0">Draft</b-badge>
                    <a class="text-muted font-weight-bold"> </a>
                  </div>
                </td>
                <td class="pl-0">
                  <div>
                    <a class="text-muted font-weight-bold">
                      {{ item.lu_datetime }}
                    </a>
                  </div>
                </td>
                <td class="text-right pr-0">
                  <router-link
                    :to="{
                      name: 'project_description.edit',
                      params: {id: item.id},
                    }"
                    class="btn btn-icon btn-light btn-sm mx-3"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </router-link>
                  <a class="btn btn-icon btn-light btn-sm" @click="deleteConfirmationModal(item.id)" ref="btnShow">
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <!--begin::Svg Icon-->
                      <inline-svg src="media/svg/icons/General/Trash.svg"></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>

  <div class="card card-custom card-stretch gutter-b" v-else>
    <!--begin::Body-->
    <div class="card-body pt-3 p-20">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import {LIST, DELETE_POST} from '../services.js';

export default {
  name: 'ListTable',
  data() {
    return {
      isReady: false,
      total_post: 0,
      dataToShow: [],
      boxTwo: '',
    };
  },
  mounted() {
    this.$store
      .dispatch(LIST)
      // go to which page after successfully login
      .then((res) => {
        this.isReady = true;
        this.dataToShow = res.data;
        this.total_post = res.data.length;
      })
      .catch((error) => {
        //console.log(error);
      });
  },
  components: {},
  methods: {
    deleteConfirmationModal(id) {
      this.boxTwo = '';
      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this post?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isReady = false;
            //console.log('Deleting post confirmation: ' + id);
            this.$store
              .dispatch(DELETE_POST, {id: id})
              // go to which page after successfully login
              .then((res) => {
                this.$store
                  .dispatch(LIST)
                  // go to which page after successfully login
                  .then((res) => {
                    this.isReady = true;
                    this.dataToShow = res.data;
                    this.total_post = res.data.length;
                  })
                  .catch((error) => {
                    //console.log(error);
                  });
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        })
        .catch((err) => {});
    },
    deleteConfirm() {},
  },
  computed: {},
};
</script>
